import React from "react";

import * as styles from './FullWidthContainer.module.scss';
import classNames from "classnames";

type propsType = {
	children: React.ReactNode,
	className?: string,
	centerContent?: boolean,
}

const FullWidthContainer = (props: propsType) => {

	let rootStyles = classNames(styles.root, props.className);

	let innerStyles = classNames(styles.inner, {[styles.center]: props.centerContent});

	return (<div className={rootStyles}>
		<div className={innerStyles}>
			{props.children}
		</div>
	</div>);
};

export default FullWidthContainer;
