export default class InternalPathService {
    static STAGING_PATH = "stage";
    static STAGING_PATH_FR = "stage";
    static BASE_URL_GERMAN = "morbusgaucher.ch";
    static BASE_URL_FRENCH = "maladie-de-gaucher.ch";
    static WWW_SUBDOMAIN = "www.";

    static isBrowserMode(): boolean {
        return typeof window !== "undefined";
    }

    static isStagingEnvironment(): boolean {
        if (InternalPathService.isBrowserMode()) {
            return window.location.hostname.startsWith(InternalPathService.STAGING_PATH)
                || window.location.hostname.startsWith(InternalPathService.STAGING_PATH_FR);
        }
        return false;
    }

    static isLocalEnvironment(): boolean {
        if (InternalPathService.isBrowserMode()) {
            return window.location.hostname === "localhost";
        }
        return false;
    }

    static isProductionEnvironment(): boolean {
        if (InternalPathService.isBrowserMode()) {
            return window.location.hostname === InternalPathService.BASE_URL_GERMAN
                || window.location.hostname === InternalPathService.WWW_SUBDOMAIN + InternalPathService.BASE_URL_GERMAN
                || window.location.hostname === InternalPathService.BASE_URL_FRENCH
                || window.location.hostname === InternalPathService.WWW_SUBDOMAIN + InternalPathService.BASE_URL_FRENCH;
        }
        return false;
    }
}