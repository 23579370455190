exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-centers-tsx": () => import("./../../../src/pages/centers.tsx" /* webpackChunkName: "component---src-pages-centers-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-diagnosis-tsx": () => import("./../../../src/pages/diagnosis.tsx" /* webpackChunkName: "component---src-pages-diagnosis-tsx" */),
  "component---src-pages-disclaimer-tsx": () => import("./../../../src/pages/disclaimer.tsx" /* webpackChunkName: "component---src-pages-disclaimer-tsx" */),
  "component---src-pages-disease-tsx": () => import("./../../../src/pages/disease.tsx" /* webpackChunkName: "component---src-pages-disease-tsx" */),
  "component---src-pages-impress-tsx": () => import("./../../../src/pages/impress.tsx" /* webpackChunkName: "component---src-pages-impress-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-order-tsx": () => import("./../../../src/pages/order.tsx" /* webpackChunkName: "component---src-pages-order-tsx" */),
  "component---src-pages-symptoms-tsx": () => import("./../../../src/pages/symptoms.tsx" /* webpackChunkName: "component---src-pages-symptoms-tsx" */),
  "component---src-pages-treatment-tsx": () => import("./../../../src/pages/treatment.tsx" /* webpackChunkName: "component---src-pages-treatment-tsx" */)
}

