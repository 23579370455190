import React from "react";
import {Link, Trans, useTranslation, useI18next} from 'gatsby-plugin-react-i18next';

import * as styles from "./Logo.module.scss"
import {LogoFr} from "./LogoFr";
import {LogoDe} from "./LogoDe";
import TranslatedLink from "../../util/TranslatedLink";

const Logo = () => {

	const { language} = useI18next();

	let logo;
	if(language === 'fr') {
		logo = <LogoFr />;
	} else {
		logo = <LogoDe />;
	}
	return (
		<div className={styles.root}>
				{logo}
		</div>
	);
}

export default Logo;
