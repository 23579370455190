import React  from "react"

import * as styles from "./MainLayout.module.scss"
import Header from "../../global/Header/Header";
import Footer from "../../global/Footer/Footer";
import {PageContextProvider} from "../../../context/PageContext";
import ProfessionalLayer from "../../organisms/ProfessionalLayer/ProfessionalLayer";
import CookieLayer from "../../organisms/CookieLayer/CookieLayer";

const MainLayout = ({children, pageContext}: {children: React.ReactNode, pageContext: any}) => {
	return (
		<PageContextProvider>
			<div className={styles.root}>
				<Header pageContext={pageContext} />
				<main>
					{children}
				</main>
				<Footer />
				<CookieLayer />
				<ProfessionalLayer />
			</div>
		</PageContextProvider>

	)
}

export default MainLayout;
