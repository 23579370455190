import React from "react";

import * as styles from "./Footer.module.scss"
import LogoTakeda from "../LogoTakeda/LogoTakeda";
import {useTranslation} from 'gatsby-plugin-react-i18next';
import TranslatedLink from "../../util/TranslatedLink";

const Footer = () => {
	const {t} = useTranslation('translation');
	const currentYear = new Date().getFullYear();

	// TODO: Implement Footer Menu as Component with translated menu items!
	return (
		<div className={styles.root}>
			<div className={styles.takedaLogo}><LogoTakeda /></div>
			<div className={styles.footerMenu}>
				<TranslatedLink title={t('footer.linkTitleImpress')} to="/impress"><span>{t('menu.imprint')}</span></TranslatedLink>
				<TranslatedLink title={t('footer.linkTitleContact')} to="/contact"><span>{t('menu.contact')}</span></TranslatedLink>
				<a title={t('footer.linkTitlePrivacyNotice')} href="https://www.takeda.com/de-ch/privacy-notice/" target="_blank" rel="noopener noreferrer"><span>{t('menu.privacy')}</span></a>
				<TranslatedLink title={t('footer.linkTitleDisclaimer')} to="/disclaimer"><span>{t('menu.disclaimer')}</span></TranslatedLink>
			</div>
			<div className={styles.copyright}>{ t('copyright', {year: currentYear})}</div>
		</div>
)};

export default Footer;
