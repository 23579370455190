// extracted by mini-css-extract-plugin
export var root = "Header-module--root--1E_yr";
export var topRow = "Header-module--top-row--3q-Ki";
export var navRow = "Header-module--nav-row--1J-pM";
export var logo = "Header-module--logo--12qIm";
export var quickNav = "Header-module--quick-nav--3kMPk";
export var nav = "Header-module--nav--1zlo5";
export var navOpen = "Header-module--nav-open--ZrMW0";
export var dbsLink = "Header-module--dbs-link--XXHPW";
export var languageSwitch = "Header-module--language-switch--1NOPV";
export var mobileMenuTrigger = "Header-module--mobile-menu-trigger--1BKij";
export var hideOnLarge = "Header-module--hide-on-large--1sgsx";