import React, {MouseEventHandler} from "react";
import classNames from "classnames";
import * as styles from "./Button.module.scss";

type props ={
    uppercase: boolean,
    children: React.ReactNode,
    inverted?: boolean,
    onSubmit?: MouseEventHandler,
    large?: boolean,
    title?: string,
}

const Button = (props: props) => {

    let className = classNames(
        styles.root,
        {[styles.uppercase]: props.uppercase},

    );

    if (props.inverted) {
        className = classNames(className, styles.inverted);
    }
    if (props.large) {
        className = classNames(className, styles.largeButton)
    }

    return (
      <div title={props.title} className={className} onClick={props.onSubmit}>
          {props.children}
      </div>
    );
}
export default Button;