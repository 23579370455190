import React from "react";
import {useI18next, useTranslation} from "gatsby-plugin-react-i18next";

import * as styles from "./Menu.module.scss";
import TranslatedLink from "../../util/TranslatedLink";
import LanguageSwitch from "../LanguageSwitch/LanguageSwitch";
import classNames from "classnames";

type propsType = {
	onSwitchPage: any,
	pageContext: any,
}
const Menu = (props: propsType) => {

	const {t} = useTranslation();

	const classesLastItem = classNames(styles.hideOnDesktop, styles.setDown);
	const {originalPath} = useI18next();

	const isCurrentPage = (pathToPage: string) => {
		return pathToPage === originalPath;
	}

	return (
		<ul className={styles.root}>
			<li className={isCurrentPage('/disease/') ? styles.currentPage : ''}><TranslatedLink onClick={props.onSwitchPage} to="/disease" title={t('heroNavigation.linktTitleDisease')}>{t('menu.disease')}</TranslatedLink></li>
			<li className={isCurrentPage('/treatment/') ? styles.currentPage : ''}><TranslatedLink onClick={props.onSwitchPage} to="/treatment" title={t('heroNavigation.linktTitleTreatment')}>{t('menu.treatment')}</TranslatedLink></li>
			<li className={isCurrentPage('/symptoms/') ? styles.currentPage : ''}><TranslatedLink onClick={props.onSwitchPage} to="/symptoms" title={t('heroNavigation.linktTitleSymptoms')}>{t('menu.symptoms')}</TranslatedLink></li>
			<li className={isCurrentPage('/diagnosis/') ? styles.currentPage : ''}><TranslatedLink onClick={props.onSwitchPage} to="/diagnosis" title={t('heroNavigation.linktTitleDiagnosis')}>{t('menu.diagnosis')}</TranslatedLink></li>
			<li className={isCurrentPage('/centers/') ? styles.currentPage : ''}><TranslatedLink onClick={props.onSwitchPage} to="/centers" title={t('heroNavigation.linktTitleCenters')}>{t('menu.centers')}</TranslatedLink></li>
			<li className={styles.hideOnDesktop}><TranslatedLink to="/order" onClick={props.onSwitchPage} title={t('heroNavigation.linktTitleOrder')}>{t('menu.dbsKit')}</TranslatedLink></li>
			<li className={styles.hideOnDesktop}><TranslatedLink className="no-text-transform" to="/imprint" onClick={props.onSwitchPage} title={t('footer.linkTitleImpress')}>{t('menu.imprint')}</TranslatedLink></li>
			<li className={styles.hideOnDesktop}><TranslatedLink className="no-text-transform" to="/contact" onClick={props.onSwitchPage} title={t('footer.linkTitleContact')}>{t('menu.contact')}</TranslatedLink></li>
			<li className={styles.hideOnDesktop}><a className="no-text-transform" href="https://www.takeda.com/de-ch/privacy-notice/" target="_blank" rel="noopener noreferrer" onClick={props.onSwitchPage} title={t('footer.linkTitlePrivacyNotice')}>{t('menu.privacy')}</a></li>
			<li className={styles.hideOnDesktop}><TranslatedLink className="no-text-transform" to="/disclaimer" onClick={props.onSwitchPage} title={t('footer.linkTitleDisclaimer')}>{t('menu.disclaimer')}</TranslatedLink></li>
			<li className={classesLastItem}><LanguageSwitch className="tmg-no-padding-left" pageContext={props.pageContext} /></li>
		</ul>
	);


}

export default Menu;
