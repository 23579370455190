import React from "react";
import Cookies from "js-cookie";
import {useTranslation} from "gatsby-plugin-react-i18next";
import Button from "../../atoms/Button/Button";
import FullWidthContainer from "../../grid/FullWidthContainer";

import * as styles from "./CookieLayer.module.scss";
import classNames from "classnames";

const CookieLayer = () => {
    const COOKIES_CONFIRMED_COOKIE = "tmg-cookies-confirmed";

    const {t} = useTranslation();

    const [showLayer, setShowLayer] = React.useState(!Cookies.get(COOKIES_CONFIRMED_COOKIE));

    if (!showLayer) {
        return <></>;
    }

    const handleLayerConfirmation = () => {
        Cookies.set(COOKIES_CONFIRMED_COOKIE, "true");
        setShowLayer(false);
    };

    const classesLeftCell = classNames(styles.leftCell, styles.cell);
    const classesRightCell = classNames(styles.rightCell, styles.cell, "tmg-colored-box");

    return (
        <div className={styles.root}>
                <FullWidthContainer>
                    <div className={styles.inner}>
                        <div className={classesLeftCell}>
                            <p>
                                {t('cookieLayer.copy')}
                            </p>
                        </div>
                        <div className={classesRightCell}>
                            <Button title={t('cookieLayer.buttonTitle')} uppercase={false} onSubmit={handleLayerConfirmation}>
                                {t('cookieLayer.button')}
                            </Button>
                        </div>
                    </div>
                </FullWidthContainer>
        </div>
    );
}

export default CookieLayer;