import React, {useState} from "react";

import { useLocation } from "@reach/router";

import * as styles from "./Header.module.scss"
import Logo from "../Logo/Logo";
import BurgerIcon from "../../icons/BurgerIcon/BurgerIcon";
import Menu from "../Menu/Menu";
import Button from "../../atoms/Button/Button";
import {useTranslation} from "gatsby-plugin-react-i18next";
import TranslatedLink from "../../util/TranslatedLink";
import LanguageSwitch from "../LanguageSwitch/LanguageSwitch";
import classNames from "classnames";

const defaultMobileNavOpen = false

const Header = ({pageContext} : {pageContext: any}) => {
	const {t} = useTranslation();
	const [isMobileMenuOpen, setMobileMenuOpen] = useState(defaultMobileNavOpen);

	const hideMenu = () => {
		const location = useLocation();
		const NO_MAIN_MENU_PATHS = ['/'];

		return NO_MAIN_MENU_PATHS.includes(location.pathname);
	}

	const menuClass = classNames(
		styles.nav,
		{[styles.navOpen]: isMobileMenuOpen},
		{[styles.hideOnLarge]: hideMenu()},

	)
	return (
		<header className={styles.root}>
			<div className={styles.topRow}>
				<div className={styles.logo}>
					<TranslatedLink to="/">

						<Logo/>
					</TranslatedLink>
				</div>
				<div className={styles.quickNav}>
					<div className={styles.dbsLink}>
						<TranslatedLink title={t('orderButton.title')} to="/order">
							<Button uppercase={true}>
								{t('menu.dbsKit')}
							</Button>
						</TranslatedLink>
					</div>
					<LanguageSwitch className={styles.languageSwitch} pageContext={pageContext} />
					<div onClick={() => setMobileMenuOpen(!isMobileMenuOpen)} className={styles.mobileMenuTrigger} >
						<BurgerIcon/>
					</div>
				</div>
			</div>
			<div className={styles.navRow}>
				<div className={menuClass}>
					<Menu onSwitchPage={() => setMobileMenuOpen(false)} pageContext={pageContext}/>
				</div>
			</div>
		</header>
	);
}

export default Header;

