import React from "react";
import Icon from "../../atoms/Icon/Icon";


const BurgerIcon = () => (
	<Icon>
		<svg xmlns="http://www.w3.org/2000/svg" width="18.214" height="14" viewBox="0 0 18.214 14">
			<g id="Hamburger_Menu" data-name="Hamburger Menu" transform="translate(0 1)">
				<line id="Linie_4" data-name="Linie 4" x2="18.214" fill="none" stroke="#5cb3b8" strokeWidth="2"/>
				<line id="Linie_5" data-name="Linie 5" x2="18.214" transform="translate(0 6)" fill="none" stroke="#5cb3b8" strokeWidth="2"/>
				<line id="Linie_6" data-name="Linie 6" x2="18.214" transform="translate(0 12)" fill="none" stroke="#5cb3b8" strokeWidth="2"/>
			</g>
		</svg>
	</Icon>
);

export default BurgerIcon;
