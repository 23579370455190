import React from "react";

import * as styles from "./ColoredBox.module.scss";
import classNames from "classnames";

type propsType = {
    children: React.ReactNode,
    className?: string
};

const ColoredBox = (props: propsType) => {
    const className = classNames(styles.root, 'tmg-colored-box', props.className);

    return (
      <div className={className}>
        <div className={styles.inner}>
          {props.children}
        </div>
      </div>
);};

export default ColoredBox;