import React from "react";
import * as styles from "./LanguageSwitch.module.scss";

import {useI18next, useTranslation} from 'gatsby-plugin-react-i18next';
import classNames from "classnames";
import InternalPathService from "../../../services/InternalPathService";

enum AvailableLanguages {
    DE = 'de',
    FR = 'fr',
}

type props = {
    className?: string,
    pageContext: any,
}
const LanguageSwitch = (props: props) => {

    const {t} = useTranslation();
    const { language } = useI18next();
    const classes = classNames(props.className, styles.root);

    const jumpToTarget = (targetLanguage: AvailableLanguages) => {
        const targetPage = props.pageContext.i18n.correspondingPathSlug ? props.pageContext.i18n.correspondingPathSlug : '/';

        if (InternalPathService.isStagingEnvironment()) {
            const targetUrl = targetLanguage === AvailableLanguages.DE ? InternalPathService.BASE_URL_GERMAN : InternalPathService.BASE_URL_FRENCH;
            const stagingPath = targetLanguage === AvailableLanguages.DE ? InternalPathService.STAGING_PATH : InternalPathService.STAGING_PATH_FR;
            window.location.replace('https://' + stagingPath + "." + targetUrl + targetPage);
        } else if (InternalPathService.isProductionEnvironment()) {
            const targetUrl = targetLanguage === AvailableLanguages.DE ? InternalPathService.BASE_URL_GERMAN : InternalPathService.BASE_URL_FRENCH;
            window.location.replace('https://' + targetUrl + targetPage);
        } else {
            window.location.replace(window.location.origin + targetPage);
        }
    }

    if(language === 'fr') {
        return (
            <div className={classes}>
                <span className={styles.text}> FR </span>
                <span className={styles.text}>|</span>
                <span> <a href='#' title={t('languageSwitch.linkLabel')} onClick={() => {
                    jumpToTarget(AvailableLanguages.DE);
                }} className={styles.text}> DE </a></span>
            </div>
        )
    } else {
        return (
            <div className={classes}>
                <span><a href='#' title={t('languageSwitch.linkLabel')} onClick={() => {
                    jumpToTarget(AvailableLanguages.FR);
                }} className={styles.text}> FR </a></span>
                <span className={styles.text}>|</span>
                <span className={styles.text}> DE</span>
            </div>
        );
    }
}
export default LanguageSwitch;