import React from "react";
import {Link, useTranslation} from "gatsby-plugin-react-i18next";

const TranslatedLink = ({to, children, onClick, className, title}: {to: string, onClick?: any, children: React.ReactNode, className?: string, title?: string}) => {

	const {t, i18n} = useTranslation();
	const language = i18n.language;

	let translatedTo = to;

	const urlMapping: {[index: string]:string} = t("urls", {returnObjects: true});

	const slug = to + '/';
	if(urlMapping[slug]) {
		 translatedTo = urlMapping[slug].substr(0, urlMapping[slug].length-1);
	}
	return (
		<Link title={title} className={className} onClick={onClick} to={translatedTo} language={language}>{children}</Link>
	);
};

export default TranslatedLink;
