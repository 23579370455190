import React from "react";

import * as styles from "./LogoTakeda.module.scss"
import {useTranslation} from "gatsby-plugin-react-i18next";
const LogoTakeda = () => {

	const {t} = useTranslation();

	return (
		<svg className={styles.root} role="img" xmlns="http://www.w3.org/2000/svg" width="96.943" height="34.561"
			 viewBox="0 0 96.943 34.561">
			<title>{t('takedaLogo')}</title>
			<g id="Logo_Takeda" data-name="Logo Takeda" transform="translate(-11966.056 -3010)">
				<path id="Pfad_1" data-name="Pfad 1"
					  d="M33.231,29.029A64.877,64.877,0,0,0,19.9,41.712l10.274,6.947.92-2.282A11.671,11.671,0,0,0,41.71,52.691H94.961c2.963.08,8.256-1.253,10.722-6.28.084.208.888,2.166.888,2.166L116.847,41.7a65.842,65.842,0,0,0-13.469-12.685,61.921,61.921,0,0,0-70.147.01"
					  transform="translate(11946.152 2991.867)" fill="#fff"/>
				<path id="Pfad_2" data-name="Pfad 2"
					  d="M52.691,34.541l.89-1.9h-11.4l-.89,1.9h4.412l-4.569,9.3h2.95l4.569-9.3Z"
					  transform="translate(11946.152 2991.867)" fill="#ed1f24"/>
				<path id="Pfad_3" data-name="Pfad 3"
					  d="M96.681,42.162s-.573.265-.822.009-.027-.733-.027-.733l2.409-5.061H95.786l-.375.673c-1.434-1.26-4.131-.608-5.8,1.138a5.444,5.444,0,0,0-1.584,3.238,7.355,7.355,0,0,1-1.319.692s-.575.262-.824.006-.026-.734-.026-.734l4.276-8.8H87.677l-2.243,4.411c-1.434-1.259-4.13-.609-5.8,1.14a6.5,6.5,0,0,0-.857,1.117v-.009l-.052.092c-.052.091-.1.181-.147.27a5.34,5.34,0,0,1-2.994,2.695c-3.506,1.049-3.909-.668-3.909-.668,3.93-1.454,5.4-3.172,5.271-4.488S75,35.769,75,35.769a6.257,6.257,0,0,0-6.163,3.93,3.767,3.767,0,0,0-.131,1.836,6.533,6.533,0,0,1-1.874.733c-.989.313-1.11-.153-1.23-.444s-.562-2.819-.562-2.819l4.167-2.726H66.657l-3.135,1.942c-.7.29-.431-.448-.431-.448l2.558-5.181H63.194L59.044,41a18.036,18.036,0,0,1-2.129,1.118s-.575.262-.825.006-.025-.734-.025-.734l2.408-5.059H56.018l-.376.674c-1.433-1.259-4.129-.609-5.8,1.14s-2.1,4.05-.954,5.142c.915.878,2.56.716,4.036-.281,0,0,.373-.322.576.057s1.056,1.272,3,.5a13.57,13.57,0,0,0,1.667-.795l-.51,1.035h2.456l1.227-2.488,1.071-.681s.325-.256.457.293a17.592,17.592,0,0,0,.736,2.365c.2.274.613.887,2.34.392a30.1,30.1,0,0,0,3.2-1.126c.576.795,1.688,1.337,3.507,1.337a8.539,8.539,0,0,0,5.4-1.954,2.137,2.137,0,0,0,.623,1.344c.915.878,2.56.716,4.036-.281,0,0,.374-.322.578.057s1.054,1.272,3,.5a9.687,9.687,0,0,0,1.884-.954,2.022,2.022,0,0,0,.477.724c.915.875,2.558.712,4.036-.284,0,0,.374-.319.576.057s1.057,1.271,3,.5a6.434,6.434,0,0,0,2.509-1.464l-.492-.868a7.48,7.48,0,0,1-1.605.884m-42.6-1.453c-.85,1.195-2.049,1.8-2.683,1.349s-.46-1.782.389-2.978,2.047-1.8,2.68-1.347.46,1.783-.386,2.976m17.4-.866a4.4,4.4,0,0,1,2.342-2.593s.506-.175.777.046c0,0,.383.373-.074,1.052a5.749,5.749,0,0,1-2.445,2.076s-.961.417-.6-.581m12.393.866c-.849,1.195-2.048,1.8-2.683,1.349s-.461-1.782.389-2.978,2.048-1.8,2.681-1.347.458,1.783-.387,2.976m9.975.045C93,41.946,91.794,42.549,91.162,42.1s-.46-1.782.387-2.975,2.048-1.794,2.682-1.348.46,1.782-.388,2.975"
					  transform="translate(11946.152 2991.867)" fill="#ed1f24"/>
				<path id="Pfad_4" data-name="Pfad 4"
					  d="M68.249,20.149A59.307,59.307,0,0,0,22.781,41.227l6.461,4.366a31.5,31.5,0,0,1,2.57-4.753,7.243,7.243,0,0,0,.1,1.193c1.14,8.855,9.748,8.644,9.748,8.644H95.017s8.719.238,9.86-8.614a7.044,7.044,0,0,0,.1-1.154,31.4,31.4,0,0,1,2.526,4.626l6.453-4.319a60.086,60.086,0,0,0-45.7-21.067m35.309,21.779a8.465,8.465,0,0,1-8.65,6.777H41.638a8.333,8.333,0,0,1-8.544-6.838,5.394,5.394,0,0,1,.624-3.916c5.393-7.1,16.063-16.627,34.507-16.627,18.606,0,29.361,9.665,34.754,16.767a4.937,4.937,0,0,1,.579,3.837"
					  transform="translate(11946.152 2991.867)" fill="#ed1f24"/>
			</g>
		</svg>

	);
};

export default LogoTakeda;
