import React, {createContext, useState} from "react";



export const PageContext = createContext({
    pageData: {},
    setPageData: {}
});

export const PageContextProvider = ({children}: {children:any}) => {
    const [pageData, setPageData] = useState({});
    return (
        <PageContext.Provider value={{pageData, setPageData}}>
            {children}
        </PageContext.Provider>
    );
}


