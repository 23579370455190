import React from "react";
import Cookies from "js-cookie";

import * as styles from "./ProfessionalLayer.module.scss";
import FullWidthContainer from "../../grid/FullWidthContainer";
import {useTranslation} from "gatsby-plugin-react-i18next";
import Button from "../../atoms/Button/Button";
import ColoredBox from "../../molecules/ColoredTextBox/ColoredBox";

const ProfessionalLayer = () => {
    const PROFESSIONAL_LAYER_COOKIE = 'tmg-professional-layer-confirmed';

    const {t} = useTranslation();

    const [showLayer, setShowLayer] = React.useState(!Cookies.get(PROFESSIONAL_LAYER_COOKIE));

    if (!showLayer) {
        return <></>;
    }

    const handleLayerConfirmation = () => {
        Cookies.set(PROFESSIONAL_LAYER_COOKIE, "true");
        setShowLayer(false);
    };

    return (
        <div className={styles.root}>
            <ColoredBox>
                <FullWidthContainer>
                    <h2>
                        {t('professionalLayer.headline')}
                    </h2>
                    <p>
                        {t('professionalLayer.copy')}
                    </p>
                </FullWidthContainer>
                <FullWidthContainer className="margin-top-4">
                    <Button title={t('professionalLayer.buttonTitle')} uppercase={true} onSubmit={handleLayerConfirmation}>
                        {t('professionalLayer.button')}
                    </Button>
                </FullWidthContainer>
            </ColoredBox>
        </div>
    );

}

export default ProfessionalLayer;